import React from "react";

const Data = () => {
  return (
    <div className="home__data">
      <h1 className="home__title">
        SOLUÇÕES EM INSTALAÇÃO E MANUTENÇÃO DE AR-CONDICIONADO
        <a
          href="https://api.whatsapp.com/send?phone=5585998143115&amp;text=Olá,%20vi%20o%20anúncio%20do%20seu%20site%20e%20preciso%20da%20sua%20ajuda!!"
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn__contact__home">
            SOLICITE UM ORÇAMENTO AGORA
          </button>
        </a>
      </h1>
    </div>
  );
};
export default Data;
